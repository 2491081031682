import { useState, useEffect } from 'react'
import posthog from 'posthog-js'

import { useAppState, useActions, useEffects } from 'store'
import ProgressCard from 'components/cards/ProgressCard'
import TextCard from 'components/cards/TextCard'
import ChartCard from 'components/cards/ChartCard'
import BetaBanner from 'components/elements/BetaBanner'
import BackNav from 'components/elements/BackNav'
import HeaderCard from 'components/cards/HeaderCard'
import FooterCard from 'components/cards/FooterCard'
import NoStudyResultsPage from 'pages/NoStudyResultsPage'
import StudyResultsErrorPage from 'pages/StudyResultsErrorPage'
import Spinner from 'components/elements/Spinner'
import { Container, SpinnerContainer, ResultsPage } from 'components/elements/Layout'

const options = {
  data: ['Completion Rate'],
  drug_use: [
    'Common Drugs Usage',
    'Your Psychedelic Use Last Year',
    'Your Psychedelic Use Lifetime',
    'Others Psychedelic Use Last Year',
    'Others Psychedelic Use Lifetime',
  ],
  longitudinal: ['Connectedness', 'Contemplation', 'Creativity', 'Focus', 'Productivity', 'Wellbeing', 'Sleep Quality'],
  scatter: ['Connectedness', 'Contemplation', 'Creativity', 'Focus', 'Productivity', 'Wellbeing', 'Sleep Quality'],
  bar: ['DASS-21', 'PANAS-10', 'Finger Tapping'],
}

declare global {
  interface Window {
    initWebView: any
    messageHandler: any
  }
}

const MDv1ResultsPage = () => {
  const [successGettingCharts, setSuccessGettingCharts] = useState<boolean | string | null>(null)
  const state = useAppState()
  const actions = useActions()
  const effects = useEffects()

  useEffect(() => {
    const chartNames = {}
    for (const chartType in options) {
      const chartsOfType = options[chartType]
      chartNames[chartType] = chartsOfType.map((chartName) => chartName.replaceAll(' ', '_').toLowerCase())
    }
    // Add global charts
    chartNames['drug_use'] = [...chartNames['drug_use'], 'global:others_psychedelic_use_lifetime', 'global:others_psychedelic_use_last_year']

    // Only for web
    // getSignedS3Url({
    //   env_name: 'services',
    //   chart_names: chartNames,
    //   qc_username: 'puwT73NPqD3iRLLUQow6xnv7HQfJ74D8laCrAqnx3GQ=@v2.quantifiedcitizen.com',
    //   qc_session_token: 'aldGBARncyrCVBNIebBVsZECRuMYF3KjN5AdFS3v5LpJfaCw8Wdn7eZungaJi8eI',
    //   qc_authorization:
    //     'Basic YWxkR0JBUm5jeXJDVkJOSWViQlZzWkVDUnVNWUYzS2pONUFkRlMzdjVMcEpmYUN3OFdkbjdlWnVuZ2FKaThlSTplZDlhNTY1MzlhMTI5ZWZkYjU3N2Q3OTBkNzFjMTNmMGI5MGUyYzQ5',
    //   study_id: '1cfe8639-5d76-4504-a7c4-34884195de69',
    //   participant_id: '006d5685-c6ad-425a-9302-4dc1e5dfcad7',
    // })

    // Assign function to window that can be called from Flutter
    window.initWebView = function (payload) {
      posthog.identify(payload.participant_id)
      posthog.register({
        environment: payload.env_name,
      })

      actions.setEnvName([payload.env_name])
      const amendedPayload = {
        chart_names: chartNames,
        ...payload,
      }

      // For testing
      // amendedPayload.study_id = '1cfe8639-5d76-4504-a7c4-34884195de69'
      // amendedPayload.participant_id = '006d5685-c6ad-425a-9302-4dc1e5dfcad7'
      // amendedPayload.force_prod_bucket = true

      const studiesData = state.studiesData.filter((studyData) => studyData['study_id'] === '1cfe8639-5d76-4504-a7c4-34884195de69')
      if (studiesData.length > 0) {
        amendedPayload.study_id = studiesData[0]['study_id']
        amendedPayload.participant_id = studiesData[0]['participant_id']
      }
      getSignedS3Url(amendedPayload)
    }

    // Notify Flutter that the function is ready to be called (to avoid race condition caused by useEffect)
    if (window?.messageHandler) {
      window.messageHandler.postMessage(JSON.stringify({ message: 'function_ready' }))
    }
  }, [])

  const getSignedS3Url = async (payload) => {
    try {
      const [signedS3Urls, chartData] = await effects.getSignedS3Urls(payload)
      actions.setChartUrls([signedS3Urls, options])
      actions.setChartData([chartData, options])

      setSuccessGettingCharts(true)
      if (window?.messageHandler) {
        window.messageHandler.postMessage(JSON.stringify({ success: true }))
      }
    } catch (e: any) {
      setSuccessGettingCharts(false)
      if (window?.messageHandler) {
        window.messageHandler.postMessage(JSON.stringify({ success: false }))
      }
    }
  }

  if (successGettingCharts === false) {
    posthog.capture('mdv1-showing-no-results')
    return <NoStudyResultsPage />
  }

  if (successGettingCharts === 'error') {
    return <StudyResultsErrorPage />
  }

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || successGettingCharts === true) {
    posthog.capture('mdv1-showing-charts')
    return (
      <Container>
        <BackNav />
        <BetaBanner />
        <ResultsPage className='p-2'>
          <HeaderCard type='mdv1' />
          <TextCard type='mdv1-welcome' />
          <ProgressCard value={(state.chartData as any)?.data?.completion_rate?.completion_rate} />
          <ChartCard chartType='drug_use' options={options.drug_use} studySlug='MDV1' />
          <TextCard type='mdv1-longitudinal' />
          <ChartCard chartType='longitudinal' options={options.longitudinal} badgeLabel='Average score: ' badgeSuffix='%' studySlug='MDV1' />
          <TextCard type='mdv1-scatter' />
          <ChartCard chartType='scatter' options={options.scatter} badgeLabel='Correlation: ' studySlug='MDV1' />
          <TextCard type='mdv1-bar' />
          <ChartCard chartType='bar' options={options.bar} showLegend studySlug='MDV1' />
          <FooterCard />
        </ResultsPage>
      </Container>
    )
  }

  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  )
}

export default MDv1ResultsPage
