import styled from '@emotion/styled'
import { useAppState, useActions } from 'store'

const gradColors = ['grad', '#F0F0F0']
const defaultColors = ['#3DDD9E', '#A77BD4', '#49B5C7', '#F0738E', '#E25E3A', '#873974']

const getLegendLabels = (studySlug, chartType, chartName) => {
  switch (studySlug) {
    case 'SOBRIETY':
      switch (chartName) {
        case 'All Sobriety':
          return ['Activity', 'Productivity', 'Sleep Quality', 'Concentration']
        default:
          return ['Sober', 'Not sober']
      }
    case 'MHTRACK':
      switch (chartName) {
        case 'DASS-21':
          return ['Depression', 'Anxiety', 'Stress']
        case 'WHO Quality of Life':
          return ['Physical Health', 'Quality of Life', 'Environmental Domain', 'Psychological Domain', 'Social Relationships', 'Satisfaction with Health']
        case 'SWLS':
          return ['Satisfaction with Life']
        case 'PANAS-10':
          return ['Positive Affect', 'Negative Affect']
        case 'FFMQ':
          return ['Observe', 'Describe', 'Act Aware', 'Non-Judge', 'Non-React']
        default:
          return []
      }
    case 'MDV1':
      switch (chartName) {
        case 'DASS-21':
          return ['Depression', 'Anxiety', 'Stress']
        case 'WHO Quality of Life':
          return ['Physical Health', 'Quality of Life', 'Environmental Domain', 'Psychological Domain', 'Social Relationships', 'Satisfaction with Health']
        case 'PANAS-10':
          return ['Positive Affect', 'Negative Affect']
        case 'FFMQ':
          return ['Observe', 'Describe', 'Act Aware', 'Non-Judge', 'Non-React']
        case 'Common Drugs Usage':
          return ['You', 'Other study participants']
        default:
          if (chartType === 'longitudinal') {
            return ['Microdosing', 'Not microdosing']
          } else {
            return []
          }
      }
    default:
      return []
  }
}

const getLongitudinalMarker = (studySlug, chartName) => {
  switch (studySlug) {
    case 'SOBRIETY':
      if (chartName === 'All Sobriety') {
        return 'line'
      } else {
        return 'circle'
      }
    case 'MDV1':
      return 'circle'
    default:
      return 'line'
  }
}

const getLongitudinalColors = (studySlug, chartName) => {
  switch (studySlug) {
    case 'SOBRIETY':
      if (chartName === 'All Sobriety') {
        return defaultColors
      } else {
        return gradColors
      }
    case 'MDV1':
      return gradColors
    default:
      return defaultColors
  }
}

const getLegendDetails = (studySlug, chartType, chartName) => {
  switch (chartType) {
    case 'longitudinal':
      const longitudinalLabels = getLegendLabels(studySlug, chartType, chartName)
      const interventionLabels = ['Microdosing', 'Not microdosing', 'Sober', 'Not sober']
      const nonInterventionLabels = longitudinalLabels.filter((label) => !interventionLabels.includes(label))
      return {
        marker: getLongitudinalMarker(studySlug, chartName),
        labels: longitudinalLabels,
        avg: nonInterventionLabels.length <= 1,
        colors: getLongitudinalColors(studySlug, chartName),
        stacking: studySlug === 'MDV1',
      }
    case 'drug_use':
      return {
        marker: 'circle',
        labels: getLegendLabels(studySlug, chartType, chartName),
        avg: false,
        colors: defaultColors,
      }
    default:
      return {
        marker: '',
        labels: [],
        avg: false,
        colors: defaultColors,
      }
  }
}

export default function Legend(props) {
  const { marker, labels, avg, colors, stacking } = getLegendDetails(props.studySlug, props.chartType, props.chartName)

  return (
    <Row>
      {labels?.map((item, i) => (
        <LegendItemWrapper key={i}>
          {marker === 'line' ? <LegendLine color={colors[i]} /> : <LegendMarker grad={colors[i] === 'grad'} color={colors[i]} />}
          <LegendLabel>{item}</LegendLabel>
        </LegendItemWrapper>
      ))}
      {avg && (
        <LegendItemWrapper>
          <AvgLine />
          <LegendLabel>Average Score</LegendLabel>
        </LegendItemWrapper>
      )}
      {stacking && (
        <>
          <LegendItemWrapper>
            <StackSquareOuter>
              <StackSquareInner />
            </StackSquareOuter>
            <LegendLabel>Stacking</LegendLabel>
          </LegendItemWrapper>
          <LegendItemWrapper>
            <StackCircleOuter>
              <StackCircleInner />
            </StackCircleOuter>
            <LegendLabel>Not Stacking</LegendLabel>
          </LegendItemWrapper>
        </>
      )}
    </Row>
  )
}

const Row = styled.div(
  (props) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    justify-content: space-around;
    flex-wrap: wrap;
  `,
)

interface LegendCirlceProps {
  grad?: boolean
}
const LegendMarker = styled.div<LegendCirlceProps>(
  (props) => `
    width: 36px;
    height: 12px;
    border-radius: 2px;
    margin-right: 6px;
    ${!props.grad ? `background-color: ${props.color}` : ''};
    ${props.grad ? 'background-image: linear-gradient(#3DDD9E, #3DDD9E66);' : ''};
  `,
)

const LegendItemWrapper = styled.div(
  (props) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 3px 8px;
    min-width: 100px;
    justify-content: center;
  `,
)

const LegendLabel = styled.div(
  (props) => `
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.2px;
    color: #1D2129;
    white-space: nowrap;
  `,
)

interface LegendLineProps {
  color: string
}

const LegendLine = styled.div<LegendLineProps>(
  (props) => `
    width: 15px;
    height: 2px;
    background-color: ${props.color};
    margin-right: 5px;
  `,
)

const AvgLine = styled.div(
  (props) => `
    width: 15px;
    height: 2px;
    border-bottom: 2px dashed #1F2937;
    margin-right: 5px;
  `,
)

const StackCircleOuter = styled.div(
  (props) => `
    width: 12px;
    height: 12px;
    border-radius: 8px;
    padding: 2px;
    margin-right: 6px;
    background-color: #3DDD9E;
  `,
)

const StackCircleInner = styled.div(
  (props) => `
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 6px;
    background-color: white;
  `,
)

const StackSquareOuter = styled.div(
  (props) => `
    width: 12px;
    height: 12px;
    margin-right: 6px;
    padding: 2px;
    background-color: #3DDD9E;
  `,
)

const StackSquareInner = styled.div(
  (props) => `
    width: 8px;
    height: 8px;
    margin-right: 6px;
    background-color: white;
  `,
)
