import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import posthog from 'posthog-js'

import { useAppState, useActions } from 'store'
import { H1, H2, H3Sans, P } from 'components/elements/Typography'
import Card from 'components/elements/Card'
import { Divider } from 'components/elements/Others'
import { Button } from 'components/elements/Button'

import { ReactComponent as NoResultSvg } from 'assets/no-results.svg'

const ResultsPage = () => {
  const [pptId, setPptId] = useState(null)
  const state = useAppState()
  const actions = useActions()
  useEffect(() => {
    // Assign function to window that can be called from Flutter
    window.initWebView = function (payload, studiesData) {
      posthog.identify(payload.participant_id)
      posthog.register({
        environment: payload.env_name,
      })

      setPptId(payload.participant_id)
      if (studiesData) {
        actions.setStudiesData([studiesData.results_study_data_list])
      }
      actions.setEnvName([payload.env_name])
    }

    // Notify Flutter that the function is ready to be called (to avoid race condition caused by useEffect)
    if (window?.messageHandler) {
      window.messageHandler.postMessage(JSON.stringify({ message: 'function_ready' }))
    }
  }, [])

  const navigate = useNavigate()

  const studiesEnrolled: any[] = []

  state.studiesData.forEach((studyData) => {
    // Sobriety production
    if (studyData['study_id'] === '5b4d96c3-677e-449a-ae28-e2e01a917042') {
      studiesEnrolled.push('sobriety')
    }

    // Sobriety integration
    if (studyData['study_id'] === 'b45f7009-e8f4-45f5-b109-8958ea9a15c4') {
      studiesEnrolled.push('sobriety')
    }

    // MHTrack production
    if (studyData['study_id'] === 'e3dd1acf-4ed8-4c12-aee8-6b4a69487202') {
      studiesEnrolled.push('mhtrack')
    }

    // MHTrack integration
    if (studyData['study_id'] === 'e3dd1acf-4ed8-4c12-aee8-6b4a69487202') {
      studiesEnrolled.push('mhtrack')
    }

    // MDv1 production
    if (studyData['study_id'] === '1cfe8639-5d76-4504-a7c4-34884195de69') {
      studiesEnrolled.push('mdv1')
    }
  })

  // For testing, force study to show up
  // studiesEnrolled.push('mdv1')

  const NoResults = () => {
    posthog.capture('showing-no-results-page')
    return (
      <PageContainer className='p-2'>
        <StyledNoResultSvg />
        <H1>No results available yet</H1>
        <P>For the studies that you enrolled in, there are no results available yet. Try completing other studies or check back later!</P>
      </PageContainer>
    )
  }

  // if (state.isProduction) {
  //   return <NoResults />
  // }

  const resultsCards: any[] = []
  if (studiesEnrolled.includes('sobriety')) {
    resultsCards.push(
      <Card>
        <HeaderContainer>
          <TextContainer>
            <H3Sans>one month sobriety challenge</H3Sans>
            <H2 style={{ marginBottom: '15px' }}>Sobriety and Well-Being</H2>
          </TextContainer>
          <IconContainer>
            <StudyIcon src={require('assets/sobriety-study-icon.png')} />
          </IconContainer>
        </HeaderContainer>
        <P>Here are your personalized results for this study! We hope this information will help you learn more about yourself.</P>
        <Divider />
        <Button onClick={() => navigate('/5b4d96c3-677e-449a-ae28-e2e01a917042')}>View results</Button>
      </Card>,
    )
  }

  if (studiesEnrolled.includes('mhtrack')) {
    resultsCards.push(
      <Card>
        <HeaderContainer>
          <TextContainer>
            <H3Sans>ongoing mental health tracker</H3Sans>
            <H2 style={{ marginBottom: '15px' }}>Mental Health Tracker</H2>
          </TextContainer>
          <IconContainer>
            <StudyIcon src={require('assets/mhtrack-study-icon.png')} />
          </IconContainer>
        </HeaderContainer>
        <P>Here are your personalized results for this study! We hope this information will help you learn more about yourself.</P>
        <Divider />
        <Button onClick={() => navigate('/e3dd1acf-4ed8-4c12-aee8-6b4a69487202')}>View results</Button>
      </Card>,
    )
  }

  if (studiesEnrolled.includes('mdv1')) {
    resultsCards.push(
      <Card>
        <HeaderContainer>
          <TextContainer>
            <H3Sans>Effects of microdosing on your mental health</H3Sans>
            <H2 style={{ marginBottom: '15px' }}>Microdose.me v1</H2>
          </TextContainer>
          <IconContainer>
            <StudyIcon src={require('assets/mhtrack-study-icon.png')} />
          </IconContainer>
        </HeaderContainer>
        <P>Here are your personalized results for this study! We hope this information will help you learn more about yourself.</P>
        <Divider />
        <Button onClick={() => navigate('/1cfe8639-5d76-4504-a7c4-34884195de69')}>View results</Button>
      </Card>,
    )
  }

  const Results = () => {
    posthog.capture('showing-results-page')
    return (
      <>
        <H1>Results report</H1>
        <P>
          The results from all the studies you completed are stored here. Look for patterns or trends. These will help you extract insights about your health.
        </P>
        {resultsCards}
      </>
    )
  }

  return <PageContainer className='p-2'>{resultsCards.length > 0 ? <Results /> : <NoResults />}</PageContainer>
}

export default ResultsPage

const StyledNoResultSvg = styled(NoResultSvg)(
  (props) => `
    width: 280px;
    height: 280px;
  `,
)

const PageContainer = styled.div(
  (props) => `
    width: 100vw;
    height: 100vh;
    min-width: 320px;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px 25px;
  `,
)

const HeaderContainer = styled.div(
  (props) => `
    display: flex;
    flex-direction: row;
  `,
)

const TextContainer = styled.div(
  (props) => `
    flex-basis: 66%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 10px;
  `,
)

const IconContainer = styled.div(
  (props) => `
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
  `,
)

const StudyIcon = styled.img(
  (props) => `
    margin: 0;
    display: block;
    max-width:230px;
    max-height:95px;
    width: auto;
    height: auto;
  `,
)
