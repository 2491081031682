const mhtrackAccordionContent = (props) => {
  switch (props.chartName) {
    case 'DASS-21':
      return 'These are your results on the Depression, Anxiety, and Stress Scale over time, a measure of each of these three areas of mental health. A higher score indicates a higher level of depression, anxiety, or stress. What do these fluctuations tell you?'
    case 'WHO Quality of Life':
      return 'The WHO Quality of Life Scale measured your physical and mental well-being. Each line represents a different domain of well-being, with a higher score denoting a higher quality of life in that area. What do these fluctuations tell you?'
    case 'SWLS':
      return 'The Satisfaction With Life Scale measures how satisfactory you perceive your life to be. A higher score indicates a higher satisfaction in your life. What do these fluctuations tell you?'
    case 'PANAS-10':
      return 'The Positive and Negative Affect Schedule is a measure of your daily mood. A higher score denotes a higher level of positive or negative affect (mood). What do these fluctuations tell you?'
    case 'FFMQ':
      return 'The Five Facet Mindfulness Questionnaire is a measure of your tendency to be mindful in daily life.  Each line represents a different facet of mindfulness. The higher the score, the more mindful you are in that facet. What do these fluctuations tell you?'
    default:
      return ''
  }
}

export default mhtrackAccordionContent
