import posthog from 'posthog-js'

export default function Accordion(props) {
  const activeChartName = props.activeChartName.replaceAll(' ', '')
  return (
    <div className='accordion accordion-flush border-gray-200' id={`accordion-${props.chartType}-${activeChartName}`}>
      <div className='accordion-item bg-white'>
        <h2 className='accordion-header mb-0' id='headingOne'>
          <button
            className='
              accordion-button
              collapsed
              relative
              flex
              items-center
              w-full
              py-4
              px-3
              mb-1
              text-base text-gray-800 text-left
              bg-white
              !rounded-none
              transition
              focus:outline-none
              leading-6
            '
            type='button'
            data-bs-toggle='collapse'
            data-bs-target={`#collapse-${props.chartType}-${activeChartName}`}
            aria-expanded='false'
            aria-controls={`collapse-${props.chartType}-${activeChartName}`}
            id={`collapse-button-${props.chartType}-${activeChartName}`}
            onClick={() => {
              const isExpanded = document.getElementById(`collapse-button-${props.chartType}-${activeChartName}`)!.getAttribute('aria-expanded')
              if (isExpanded === 'false') {
                posthog.capture('accordion_opened', { chart_name: activeChartName, study: props.studySlug, chart_Type: props.chartType })
              } else {
                posthog.capture('accordion_closed', { chart_name: activeChartName, study: props.studySlug, chart_Type: props.chartType })
              }
            }}
          >
            {props.title}
          </button>
        </h2>
        <div
          id={`collapse-${props.chartType}-${activeChartName}`}
          className='accordion-collapse collapse border-t-0'
          aria-labelledby='headingOne'
          data-bs-parent={`accordion-${props.chartType}-${activeChartName}`}
        >
          <div className='accordion-body pb-2 px-3 text-sm mb-4'>
            <props.content chartType={props.chartType} chartName={props.activeChartName} />
          </div>
        </div>
      </div>
    </div>
  )
}
