const mdv1AccordionContent = (props) => {
  const chartName = props.chartName.toLowerCase()

  switch (props.chartType) {
    case 'drug_use':
      switch (props.chartName) {
        case 'Common Drugs Usage':
          return 'Here is how often you and other study participants consume these common substances.'
        case 'Your Psychedelic Use Last Year':
          return 'This year, these are the psychedelics you consumed the most (a maximum of three).'
        case 'Your Psychedelic Use Lifetime':
          return 'Across your entire lifetime, these are the psychedelics you consumed the most (a maximum of three).'
        case 'Others Psychedelic Use Last Year':
          return 'Percent of study participants reporting having used each substance last year.'
        case 'Others Psychedelic Use Lifetime':
          return 'Percent of study participants reporting having used each substance in their lifetime.'
        default:
          return ''
      }
    case 'longitudinal':
      return `This is your ${chartName} score over time based on the daily reports. A higher score means higher ${chartName}. Does your ${chartName} increase/decrease on the days you have been microdosing/stacking?`
    case 'scatter':
      return (
        <div>
          <ul>
            <li>
              Check out how study days <b>relate</b> to your {chartName}. Is your {chartName} score better or worse the more time you spent in the study?
            </li>
            <li>
              Each dot represents one of your data entries. The <b>correlation value</b> between the number of study days and {chartName} score is shown in the
              top right. A <b>positive correlation</b> (maximum +1.0) means the two measures move in the same direction: when one increases, the other will
              likely increase as well. A <b>negative correlation</b> (minimum -1.0) indicates that they move in opposite directions. If the value is zero, there
              is no relationship between measures.
            </li>
            <li>
              If you have submitted at least 10 daily ratings, you will see a green <b>regression line</b> on your plot. This line represents the general
              association between number of study days and {chartName}.
            </li>
            <li>
              The green <b>shaded area</b> around the regression line is called a 95% confidence interval. This indicates a range of values that your scores
              will most likely fall within.
            </li>
          </ul>
        </div>
      )
    case 'bar':
      switch (props.chartName) {
        case 'DASS-21':
          return 'Here are your scores on the DASS-21 at the beginning of the study (baseline) and throughout the study duration. The higher the score, the more depression, anxiety, or stress-related symptoms have been reported. Do you see changes in your scores? What do you think they indicate?'
        case 'PANAS-10':
          return 'Here are your scores on the PANAS-10 at the beginning of the study (baseline) and throughout the study duration. The higher the score on the positive dimension, the higher your ratings were for positive affect (e.g., interested, excited). The higher the score on the negative dimension, the higher your ratings were for negative affect (e.g., distressed, upset). Do you see changes in your scores? What do you think they indicate?'
        case 'Finger Tapping':
          return 'Here is the total number of taps you performed each month starting at baseline. The higher the number, the more you tapped! Do you see changes in your scores?'
        default:
          return null
      }
    default:
      return null
  }
}

export default mdv1AccordionContent
