import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import SVG, { Props as SVGProps } from 'react-inlinesvg'
import posthog from 'posthog-js'

import { useAppState, useActions } from 'store'
import Card from 'components/elements/Card'
import Select from 'components/Select'
import Accordion from 'components/elements/Accordion'
import Legend from 'components/elements/Legend'
import ChartFader from 'components/elements/ChartFader'
import Fader from 'components/elements/Fader'
import getAccordionBody from 'components/content/accordionBody/accordionBody'

const InlineAvgLegend = () => (
  <LegendItemOuterWrapper>
    <LegendItemWrapper>
      <AvgLine />
      <LegendLabel>Population Average</LegendLabel>
    </LegendItemWrapper>
  </LegendItemOuterWrapper>
)

export default function ChartCard(props) {
  const state = useAppState()
  const actions = useActions()
  const [chartContent, setChartContent] = useState<string>('')

  useEffect(() => {
    if (props.chartType !== 'longitudinal') {
      updateChartContent()
    }
  }, [state.activeCharts?.[props.chartType]?.url])

  const updateChartContent = async () => {
    // Keep placeholder text
    if (state.activeCharts[props.chartType].url === 'no-chart') {
      setChartContent('no-chart')
      return
    }
    const response = await fetch(state.activeCharts[props.chartType].url)
    const content = await response.text()
    setChartContent(content)
  }

  const onChartSelected = (newSelectedName) => {
    if (state.activeCharts?.[props.chartType]?.name !== newSelectedName) {
      actions.setSelectedChart([props.studySlug, props.chartType, newSelectedName])
      posthog.capture(`chart_selected`, { chart_name: newSelectedName, study: props.studySlug, chart_Type: props.chartType })
    }
  }

  const chart = state.activeCharts[props.chartType]
  const { accordionTitle, badgeValue, title, subtitle, name, url } = chart || {}
  return (
    <Card title={title} subtitle={subtitle} smallTitle chart>
      <Row>
        <Select selected={chart} setSelected={onChartSelected} options={props.options} />
        {props.badgeLabel && badgeValue && (
          <Badge>
            {props.badgeLabel}
            {badgeValue}
            {props.badgeSuffix || ''}
          </Badge>
        )}
        {props.showInlineLegend && state.activeCharts?.[props.chartType]?.name === 'Wellbeing' && <InlineAvgLegend />}
      </Row>
      <Legend studySlug={props.studySlug} chartType={props.chartType} chartName={chart?.name} />
      {props.chartType === 'longitudinal' && <ChartFader src={url} component={ChartSlot} height='290px' />}
      {props.chartType !== 'longitudinal' && <ChartFader src={chartContent} component={SVG} height='290px' />}
      <Fader transitionKey={accordionTitle}>
        {accordionTitle && (
          <AccordionWrapper>
            <Accordion
              title={accordionTitle}
              content={getAccordionBody(props.studySlug)}
              chartType={props.chartType}
              activeChartName={name}
              studySlug={props.studySlug}
            />
          </AccordionWrapper>
        )}
      </Fader>
    </Card>
  )
}

const Row = styled.div(
  (props) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
)

const Badge = styled.div(
  (props) => `
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    height: 22px;
    background: #CFFCE4;
    border-radius: 400px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #007D55;
  `,
)

const ChartSlot = styled.iframe(
  (props) => `
    height: 270px;
    min-height: 270px;
    max-height: 270px;
    width: 100%;
  `,
)

const AccordionWrapper = styled.div(
  (props) => `
    // margin-bottom: -20px;
  `,
)

const LegendItemOuterWrapper = styled.div(
  (props) => `
    display: flex;
    align-items: center;
  `,
)

const LegendItemWrapper = styled.div(
  (props) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100px;
    justify-content: center;
  `,
)

const LegendLabel = styled.div(
  (props) => `
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.2px;
    color: #1D2129;
  `,
)

const AvgLine = styled.div(
  (props) => `
    width: 15px;
    height: 2px;
    border-bottom: 2px dashed #1F2937;
    margin-right: 5px;
  `,
)
