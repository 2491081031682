import styled from '@emotion/styled'
import posthog from 'posthog-js'

import { useAppState, useActions } from 'store'
import Card from 'components/elements/Card'

export default function FooterCard(props) {
  return (
    <Card noBg noPad>
      <CenteredP>Missing something that you would like to see?</CenteredP>
      <LinkContainer>
        <Link
          className='text-primary'
          href='https://survey.hsforms.com/1onJh_3R-SD2BykB99dTYbgca2xk'
          onClick={() => posthog.capture('n_of_1_feedback_clicked')}
        >
          Let us know!
        </Link>
      </LinkContainer>
    </Card>
  )
}

const Link = styled.a(
  (props) => `
    text-decoration: underline;
    text-align: center;
    font-weight: 700;
  `,
)

const CenteredP = styled.div(
  (props) => `
    text-align: center;
  `,
)

const LinkContainer = styled.div(
  (props) => `
    display: flex;
    justify-content: center;
    align-items: center;
  `,
)
